import JSBI from 'jsbi'

export const FACTORY_ADDRESS = '0x0000000000000000000000000000000000006d50'

export const INIT_CODE_HASH = '0xa46f7ab029b76500bbb6a89374c08caac49138f45c7b9f759ca67e9296501ef1'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _1000 = JSBI.BigInt(1000)
