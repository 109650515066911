import { pack } from '@ethersproject/solidity'
import { ChainId, Currency, Token, WETH9 } from '@uniswap/sdk-core'
import { Pool } from '../entities/pool'
import { Route } from '../entities/route'

/**
 * Converts a route to a hex encoded path
 * @param route the v3 path to convert to an encoded path
 * @param exactOutput whether the route should be encoded in reverse, for making exact output swaps
 */
export function encodeRouteToPath(route: Route, exactOutput: boolean): string {
  const firstInputToken: Token | undefined =
    route.input instanceof Token
      ? route.input
      : route.input === Currency.ETHER
      ? WETH9[route.chainId as ChainId]
      : undefined

  if (!firstInputToken) throw new Error('Could not wrap input currency')

  const { path, types } = route.pools.reduce(
    (
      { inputToken, path, types }: { inputToken: Token; path: (string | number)[]; types: string[] },
      pool: Pool,
      index
    ): { inputToken: Token; path: (string | number)[]; types: string[] } => {
      const outputToken: Token = pool.token0.equals(inputToken) ? pool.token1 : pool.token0
      if (index === 0) {
        return {
          inputToken: outputToken,
          types: ['address', 'uint24', 'address'],
          path: [inputToken.address, pool.fee, outputToken.address]
        }
      } else {
        return {
          inputToken: outputToken,
          types: [...types, 'uint24', 'address'],
          path: [...path, pool.fee, outputToken.address]
        }
      }
    },
    { inputToken: firstInputToken, path: [], types: [] }
  )

  return exactOutput ? pack(types.reverse(), path.reverse()) : pack(types, path)
}
